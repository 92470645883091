
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useForm, validators } from '@/composables/useForm'
import FieldText from '@/components/FieldText.vue'
import Button from '@/components/Button.vue'
import { callers } from '@/api'
import { useStore } from 'vuex'
import { useSubmitter } from '@/composables/useSubmitter'
import { notifySuccess } from '@/helpers/notify'

export default defineComponent({
  components: { FieldText, Button },
  setup() {
    const { t } = useI18n()
    const { getters, dispatch } = useStore()

    const form = useForm({
      oldPassword: ['', validators.required],
      newPassword: ['', validators.required],
      newPasswordAgain: ['', validators.required],
    })
    form.newPasswordAgain.addValidator(
      validators.shouldMatch(
        () => form.newPassword.val(),
        t('passwords-mismatch-err')
      ),
      form.newPassword.current
    )
    const [submit, isSubmitting] = useSubmitter({
      request: () =>
        callers.changePassword({
          email: getters['USER_EMAIL'],
          oldPassword: form.oldPassword.val(),
          newPassword: form.newPassword.val(),
        }),
      guard: () => form.checkValid(),
      onSuccess: ({ data: token }) => {
        form.reset()
        dispatch('LOG_IN', { token })
        notifySuccess({ title: t('changed-msg') })
      },
    })

    return {
      form: form.flatten(),
      isSubmitting,
      submit,
      email: getters['USER_EMAIL'],
      t,
    }
  },
})
