export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en-US",
    "resource": {
      "old-password-lbl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password"])},
      "old-password-ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password"])},
      "new-password-lbl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
      "new-password-ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
      "new-password-again-lbl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password again"])},
      "new-password-again-ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password again"])},
      "passwords-mismatch-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords should match"])},
      "submit-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
      "submitting-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changing…"])},
      "changed-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password changed!"])}
    }
  })
}
